import { getNumberFromDate } from 'utils/dateUtil'

const date = new Date()
export const F_NUM = -1
export const F_STR = ''
export const F_YM = getNumberFromDate(date, 6)
export const F_YM_BEFORE = getNumberFromDate(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()), 6)
export const F_ARR = []
export const HAN_KBN = 1
export const KURA_KBN = 2
export const HAN_SYUSEI_KBN = 3 // 修正CSV出力対応 修正出力区分追加  20250219 原
export const KURA_SYUSEI_KBN = 4 // 修正CSV出力対応 修正出力区分追加  20250221 原
