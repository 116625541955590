import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import M_ButtonNormal from 'components/1_Atoms/2_Molecules/M_ButtonNormal'
// import O_TablePagination from './O_TablePagination'
import { organismLog } from 'utils/logUtil'
import M_ButtonAlert from 'components/1_Atoms/2_Molecules/M_ButtonAlert'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import { buttonQueryF, clickFetchF, voidF } from 'types/FunctionTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    hit: {
      marginRight: theme.spacing(1),
      // width: 250
    },
    buttons: {
      // flexGrow: 1,
      // width: 190
    },
    paddingRight0: {
      paddingRight: '0px',
    },
    backButton: {
      // flexGrow: 1,
      // display: 'flex',
      // justifyContent: 'flex-end',
    },
  }),
)
const margin = 0
const paddingX = 0.5
const paddingY = 0

type Props = {
  count?: number
  checkedList?: boolean[]
  backPath?: string
  clickSendMail: buttonQueryF
  clickAdd: clickFetchF | voidF
  clickUpdate: clickFetchF
  clickDelete: clickFetchF | voidF
  clickBack: voidF
  refetch: voidF
  clickCSV: buttonQueryF // ユーザ一覧CSV出力対応 CSV出力ボタン追加 20250303 原
}

// ユーザ一覧CSV出力対応 CSV出力ボタン追加 20250303 原
// const O_TableBarUserDetail = ({ count = 0, checkedList = [false, false, false, false, false], clickSendMail, clickAdd, clickUpdate, clickDelete, clickBack, refetch }: Props) => {
const O_TableBarUserDetail = ({ count = 0, checkedList = [false, false, false, false, false], clickSendMail, clickAdd, clickUpdate, clickDelete, clickBack, refetch, clickCSV }: Props) => {
  useEffect(() => organismLog('O_TableBarUserDetail'))
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Toolbar variant="dense" className={classes.paddingRight0}>
        <Typography className={classes.hit} color="inherit" variant="subtitle1" component="div">
          {count}件
        </Typography>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonNormal label={'登録ﾒｰﾙ再送'} event={clickSendMail} disabled={!checkedList[0]} />
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonNormal label={'追加'} event={(e) => clickAdd(e, refetch)} disabled={!checkedList[1]} />
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonNormal label={'更新'} event={(e) => clickUpdate(e, refetch)} disabled={!checkedList[2]} />
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonAlert label={'削除'} event={(e) => clickDelete(e, refetch)} disabled={!checkedList[3]} />
        </Box>
        {/* ユーザ一覧CSV出力対応 CSV出力ボタン追加 20250303 原 */}
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonNormal label={'ユーザ一覧CSV出力'} event={clickCSV} />
        </Box>
        <Box className={classes.root}></Box>
        <Box>{/* <O_TablePagination count={count} /> */}</Box>
        <Box className={classes.backButton} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonSubmit label={'戻る'} event={clickBack} disabled={!checkedList[4]} />
        </Box>
      </Toolbar>
    </div>
  )
}

export default React.memo(O_TableBarUserDetail)
