import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as SearchAction from 'actions/SearchActions/SearchJokenRitsuAction'
import * as TableAction from 'actions/TableActions/TableJokenRitsuAction'
import * as PageAction from 'actions/PageAction'
import { getRequestJokenRitsu, getRequestRitsuCSV, getRequestRitsuCSVAll, search } from 'utils/apiUtil'
import { buttonQueryF, clickFetchF, voidF } from 'types/FunctionTypes'
import { requestRitsuAllCSV, requestRitsuCSV, requestRitsuSearch } from 'types/RequestTypes'
import { checkListRitsu } from 'types/SearchTypes'
import { createCSV } from 'utils/fileUtil'
import { fileCode } from 'assets/FileName'
import { CSVHeaderPreRitsuJoken, CSVHeaderPreRitsuJokenSiire } from 'assets/CSVHeaders'
import { useGetIsHiddenPagination } from './useRitsuJoken'
import { F_NUM } from 'assets/FormatNumbers'

/**
 * ボタン押下処理
 * @param param0
 * @returns
 */
export const useButtons = (jokenListCount: number, allCount: number): [boolean[], clickFetchF, clickFetchF, clickFetchF, clickFetchF, clickFetchF, buttonQueryF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)
  const searchJoken = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu)
  const selectJoken = useSelector((state) => state.TableJokenRitsuReducer.selectJoken).filter((r) => r.isChecked)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  const isHiddenPagination = useGetIsHiddenPagination()
  // 全件処理フラグ
  const isAllProcess = allCount > perPage && jokenListCount === selectJoken.length && !isHiddenPagination

  type requestRitsu = { checkListRitsu: checkListRitsu[] }
  const request = {
    checkListRitsu: selectJoken,
    MAIL: MAIL,
    TANTO_CD: TANTO_CD,
    JOTAI: {
      mishonin: searchJoken.mishoninCheckbox,
      shonin: searchJoken.shoninCheckbox,
      hinin: searchJoken.hininCheckbox,
    },
  }
  // 全件処理用のリクエスト
  const requestAll = getRequestJokenRitsu(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, searchJoken)
  const regist = (refetch: voidF) => {
    return (res: any) => {
      if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
      else {
        dispatch(TableAction.changeCheck(res.body))
        dispatch(PageAction.changeMessage(res.viewMsg, res.alert ? 'red' : 'blue'))
        // refetch後メッセージを削除されなくする
        dispatch(SearchAction.changeIsDeleteMessage(true))
        refetch()
      }
    }
  }
  const changeErrMsg = () => dispatch(PageAction.changeErrorMessage()) //
  const loaded = () => dispatch(PageAction.changeLoading(false)) //

  // ボタン活性状況 //================================//
  const disabled1 = selectJoken.length <= 0 // 1つ目のボタンの活性状況(CSV出力)
  let disabled2 = disabled1 // 2つ目のボタンの活性状況(承認)
  if (userKind === 0) {
    // ジャペルの場合(確認済)
    disabled2 = !selectJoken.some((r) => r.JOTAI_KBN === 2 && r.KAKUNIN_KBN === 0)
  }
  const disabled3 = selectJoken.length <= 0 // 3つ目のボタンの活性状況(否認)
  const disabled4 = !selectJoken.some((r) => r.JOTAI_KBN === 0 || r.JOTAI_KBN === 1) // 4つ目のボタンの活性状況(上長承認)
  const disabled5 = !selectJoken.some((r) => r.JOTAI_KBN === 0 || r.JOTAI_KBN === 1) // 5つ目のボタンの活性状況(上長否認)

  // 承認ボタン押下処理 //================================//
  const clickShonin = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      if (isAllProcess) {
        // 全件選択の場合
        await search<requestRitsuSearch, checkListRitsu>('/postJokenRitsuShoninBulkAll', requestAll, regist(refetch), changeErrMsg, loaded)
      } else {
        // 全件選択でない場合
        await search<requestRitsu, checkListRitsu>('/postJokenRitsuShoninBulk', request, regist(refetch), changeErrMsg, loaded)
      }
      dispatch(PageAction.changeSideMenu())
    }
  }

  // 否認ボタン押下処理 //================================//
  const clickHinin = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      await search<requestRitsu, checkListRitsu>('/postJokenRitsuHininBulk', request, regist(refetch), changeErrMsg, loaded)
      dispatch(PageAction.changeSideMenu())
    }
  }

  // 確認済ボタン押下処理 //================================//
  const clickKakuninzumi = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      if (isAllProcess) {
        // 全件選択の場合
        await search<requestRitsuSearch, checkListRitsu>('/postJokenRitsuKakuninBulkAll', requestAll, regist(refetch), changeErrMsg, loaded)
      } else {
        // 全件選択でない場合
        await search<requestRitsu, checkListRitsu>('/postJokenRitsuKakuninBulk', request, regist(refetch), changeErrMsg, loaded)
      }
      dispatch(PageAction.changeSideMenu())
    }
  }

  // 上長承認ボタン押下処理 //================================//
  const clickJochoShonin = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      if (isAllProcess) {
        // 全件選択の場合
        await search<requestRitsuSearch, checkListRitsu>('/postJokenRitsuJochoShoninBulkAll', requestAll, regist(refetch), changeErrMsg, loaded)
      } else {
        // 全件選択でない場合
        await search<requestRitsu, checkListRitsu>('/postJokenRitsuJochoShoninBulk', request, regist(refetch), changeErrMsg, loaded)
      }
      dispatch(PageAction.changeSideMenu())
    }
  }

  // 上長否認ボタン押下処理 //================================//
  const clickJochoHinin = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      await search<requestRitsu, checkListRitsu>('/postJokenRitsuJochoHininBulk', request, regist(refetch), changeErrMsg, loaded)
      dispatch(PageAction.changeSideMenu())
    }
  }

  // CSVボタン押下処理 //================================//
  const clickCSV = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      if (isAllProcess) {
        // 全件選択の場合

        if (!window.confirm(`${allCount}行あります。行数によっては時間がかかります。よろしいでしょうか？`)) {
          loaded()
          return
        }

        await search<requestRitsuAllCSV, any>(
          '/postJokenRitsuCSVAll',
          getRequestRitsuCSVAll(fileCode.RITSU_CSV, requestAll),
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              createCSV(userKind === 0 ? CSVHeaderPreRitsuJoken : CSVHeaderPreRitsuJokenSiire, res.body, res.fname)
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
            }
          },
          changeErrMsg,
          loaded,
        )
      } else {
        // 全件選択でない場合
        await search<requestRitsuCSV, any>(
          '/postJokenRitsuCSV',
          getRequestRitsuCSV(fileCode.RITSU_CSV, selectJoken),
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              createCSV(userKind === 0 ? CSVHeaderPreRitsuJoken : CSVHeaderPreRitsuJokenSiire, res.body, res.fname)
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
            }
          },
          changeErrMsg,
          loaded,
        )
      }
    }
  }

  return [[disabled1, disabled2, disabled3, disabled4, disabled5], clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV]
}

// 検索後フォーマット処理 //================================//
export const useSearchedFormat = (): voidF => {
  const dispatch = useDispatch()
  const format = () => {
    dispatch(TableAction.clearCheck())
    // dispatch(PageAction.deleteMessage())
  }
  return format
}
