/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { TableVirtuoso } from 'react-virtuoso'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import O_TableBarKuraDetail from 'components/3_Organisms/O_TableBarKuraDetail'
import O_TableRowJokenKuraDetail from 'components/3_Organisms/O_TableRowJokenKuraDetail'
import O_TableHeaderJokenKuraDetail from 'components/3_Organisms/O_TableHeaderJokenKuraDetail'
import O_FileUpload from 'components/3_Organisms/Groups/O_FileUpload'
import { useFetcherDetail, useInitProcessDetail } from 'hooks/useKuraJoken'
import { useButtonsDetail, useCheckListDetail, useGetIsHiddenPaginationDetail, useSearchedFormatDetail } from 'hooks/useKuraJokenTable'
import { usePageDetail } from 'hooks/useTable'
import { useLoading, usePageMessage } from 'hooks/usePage'
import { organismGroupLog } from 'utils/logUtil'
import { isUniqueJokenDetail } from 'utils/booleanUtil'
import { responseDetailJokenKura } from 'types/ResponseTypes'
import { useChangeTableHeight, useChangeInnerHeight } from 'hooks/useCommon'
import { commonMsg } from 'assets/MessageCode'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    container: {
      maxHeight: 600,
    },
    renkeizumi: {
      backgroundColor: '#D8D8D8',
    },
    selectedRenkeizumi: {
      backgroundColor: '#AAAAAA',
    },
  }),
)

const O_TableJokenKuraDetail = () => {
  const location = useLocation()
  const initProcess = useInitProcessDetail(location.pathname, useHistory())
  useEffect(() => {
    organismGroupLog('O_TableJokenKuraDetail')
    initProcess()
  })
  const classes = useStyles()
  const [checkListDetail, checkListDetailAll, allCheck] = useCheckListDetail()
  const [page, perPage] = usePageDetail()
  const searchedFormat = useSearchedFormatDetail()
  const fetcher = useFetcherDetail()
  const [tableHeight, setTableHeight] = useState(window.innerHeight)
  const [currentInnerHeight, setCurrentInnerHeight] = useState(window.innerHeight)
  const [previousInnerHeight, setPreviousInnerHeight] = useState(window.innerHeight)
  const [changeTableHeight] = useChangeTableHeight(tableHeight, currentInnerHeight, previousInnerHeight, setTableHeight)
  const [changeInnerHeight] = useChangeInnerHeight(currentInnerHeight, setCurrentInnerHeight, setPreviousInnerHeight)
  const [changePageErrorMessage] = usePageMessage()
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)

  useEffect(() => {
    // ウィンドウサイズ変更時にchangeInnerHeightを呼び出すようにリスナーに登録
    window.removeEventListener('resize', changeInnerHeight)
    window.addEventListener('resize', changeInnerHeight)
    return () => {
      window.removeEventListener('resize', changeInnerHeight)
    }
  }, [window.innerHeight, currentInnerHeight])
  useEffect(() => {
    // useEffectが呼ばれるのはウィンドウサイズを変えたとき、スクロール含めた高さが変わったとき（初期表示時にも調整するため）
    // ウィンドウサイズを変えると上記2パターンに引っ掛かり2回動くので
    // changeTableHeightを呼ぶのはcurrentInnerHeightを更新した後のみにする
    if (currentInnerHeight === window.innerHeight) changeTableHeight()
  }, [document.documentElement.scrollHeight, currentInnerHeight])

  // SWR情報の取得
  const clickNo = useSelector((state) => state.TableJokenKuraReducer.clickNo)
  const { data, refetch, isLoading, isFetching, error } = useQuery(['postJokenDetail', clickNo, page, perPage], fetcher)
  const [loding, changeLoading] = useLoading()
  useEffect(() => {
    if (isLoading || isFetching) {
      changeLoading(true)
    }
    if (!(isLoading || isFetching) && loding) {
      changeLoading(false)
    }
  }, [isLoading, isFetching])
  const isHiddenPagination = useGetIsHiddenPaginationDetail()
  const info: { table: responseDetailJokenKura[]; allCount: number } = data?.body
  // 修正CSV出力対応 修正出力ボタン追加  20250221 原
  // const [_, userKind, checkedList, clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV, clickBack] = useButtonsDetail(useHistory(), info ? info.table.length : 0, info ? info.allCount : 0)
  const [_, userKind, checkedList, clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV, clickBack, clickCSVSyusei] = useButtonsDetail(useHistory(), info ? info.table.length : 0, info ? info.allCount : 0)

  const detailList = info?.table

  const getIsAllChecked = () => {
    if (!detailList) return false
    if (detailList.length === 0 || checkListDetail.length === 0) return false
    return detailList.length === checkListDetail.length
  }
  const getIsChecked = (detail: responseDetailJokenKura) => {
    if (getIsAllChecked()) return true
    if (checkListDetail.length <= 0) return false
    return checkListDetail.some((r) => isUniqueJokenDetail(r, detail))
  }
  const table = (props: any) => <Table {...props} style={{ borderCollapse: 'separate' }} size="small" aria-label="purchases" />
  const tableRow = (props: any) => {
    let isChecked = getIsChecked(props.item)
    let isCheckedTemp = isChecked
    let setClass = undefined
    if (userKind === 1 && props.item.RENKEI_KBN === 1) {
      if (isCheckedTemp) {
        setClass = classes.selectedRenkeizumi
        isCheckedTemp = false
      } else {
        setClass = classes.renkeizumi
      }
    }
    return <TableRow hover role="checkbox" {...props} selected={isCheckedTemp} className={setClass} />
  }
  const getComponent = useCallback(() => {
    return {
      Scroller: TableContainer,
      // Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} className={classes.container} ref={ref} />),
      Table: table,
      TableHead: TableHead,
      TableRow: tableRow,
      // TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      TableBody: TableBody,
    }
  }, [checkListDetail.length])
  if (isLoading) {
    searchedFormat()
    return <div>Loading...</div>
  }
  if (isFetching) {
    searchedFormat()
    return <div>検索中...</div>
  }
  if (error) {
    changePageErrorMessage(commonMsg.ERROR_MSG)
    return <></>
  }
  const list = detailList

  const getTableBar = () => {
    return (
      <O_TableBarKuraDetail
        count={info.allCount} //
        checkedList={checkedList}
        userKind={userKind}
        clickShonin={clickShonin}
        clickHinin={clickHinin}
        clickKakuninzumi={clickKakuninzumi}
        clickJochoShonin={clickJochoShonin}
        clickJochoHinin={clickJochoHinin}
        clickCSV={clickCSV}
        clickBack={clickBack}
        refetch={refetch}
        clickCSVSyusei={clickCSVSyusei} // 修否認SV出力対応 修否認力ボタン追加  20250221 原
      />
    )
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TableVirtuoso
            style={{ height: tableHeight }} // 仮想リスト(表示範囲250以降はスクロール毎に描画する)
            data={list}
            components={getComponent()}
            fixedHeaderContent={() => <O_TableHeaderJokenKuraDetail click={allCheck(detailList, getIsAllChecked())} isChecked={getIsAllChecked()} />}
            itemContent={(index, detail) => {
              const no = isHiddenPagination ? index + 1 : index + page * perPage + 1
              const isChecked = getIsChecked(detail)
              const checkShohin = checkListDetailAll.filter((r) => isUniqueJokenDetail(r, detail))
              let HININ_RIYU = ''
              if (checkShohin.length > 0) {
                HININ_RIYU = isJocho ? checkShohin[0].JOCHO_HININ_RIYU : checkShohin[0].HININ_RIYU
              } else {
                HININ_RIYU = isJocho ? detail.JOCHO_HININ_RIYU : detail.HININ_RIYU
              }
              return (
                <O_TableRowJokenKuraDetail
                  detail={detail} //
                  isChecked={isChecked}
                  HININ_RIYU={HININ_RIYU}
                  no={no}
                />
              )
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
      </Grid>
      {/* ファイル取込画面のモーダル */}
      <O_FileUpload INP_KBN={4} refetch={refetch} />
    </div>
  )
}

export default React.memo(O_TableJokenKuraDetail)
