/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import C5_Tenyuryoku from './Contents/C3_Tenyuryoku'
import T_Main from 'components/4_Templates/T_Main'
import { menu } from 'types/ReferTypes'

const P_Tenyuryoku = ({ sysName, menus }: { sysName: string; menus: menu[] }) => {
  // ログインユーザーの種類(0: ジャペル, 1: 仕入先)
  const loginKbn = useSelector((state) => state.LoginReducer.loginKbn)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    if (loginKbn !== 2) {
      dispatch(LoginAction.format())
      history.push('/') // ログイン画面に遷移
    } else {
      dispatch(PageAction.deleteMessage())
    }
  }, [])

  return (
    <T_Main sysName={sysName} menus={menus} pageNo={3} expandMode={true}>
      <C5_Tenyuryoku />
    </T_Main>
  )
}

export default P_Tenyuryoku
