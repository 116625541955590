import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as TableAction from 'actions/TableActions/TableTenyuryokuAction'
import * as SearchAction from 'actions/SearchActions/SearchTenyuryokuAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import { checkListDetail, checkListDetailTenyuryoku, checkListSummaryTenyuryoku } from 'types/SearchTypes'
import { getRequestTenyuryoku, getRequestTenyuryokuDetail, search } from 'utils/apiUtil'
import { buttonQueryF, clickFetchF, voidF } from 'types/FunctionTypes'
import { fileCode } from 'assets/FileName'
import { createCSV } from 'utils/fileUtil'
import { CSVHeaderTenyuryoku, CSVHeaderTenyuryokuSiire } from 'assets/CSVHeaders'
import { requestDetailTenyuryokuCSV, requestSummaryTenyuryokuUpdate, requestSummaryTenyuryokuCSV, requestSummaryTenyuryokuAllCSV, requestTenyuryokuSummary, requestTenyuryokuDetail, requestDetailTenyuryokuAllCSV, requestSummaryTenyuryokuAllCountCSV } from 'types/RequestTypes'
import { responseDetailTenyuryoku, responseSummaryTenyuryoku } from 'types/ResponseTypes'
import { F_NUM } from 'assets/FormatNumbers'
import { jotai } from 'types/ReferTypes'

// サマリチェックリスト //================================//
type check = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
export const useCheckListSummary = (tenyuryokuList: responseSummaryTenyuryoku[]): [checkListSummaryTenyuryoku[], check] => {
  const dispatch = useDispatch()
  const checkListTenyuryoku = useSelector((state) => state.TableTenyuryokuReducer.checkListTenyuryoku)
  const allClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => {
      e.preventDefault()
      dispatch(TableAction.allCheck(isChecked, tenyuryokuList))
    },
    [tenyuryokuList],
  )
  return [checkListTenyuryoku, allClick]
}

// 明細チェックリスト //================================//
type checkD = (shohinList: responseDetailTenyuryoku[], isChecked: boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
export const useCheckListDetail = (): [checkListDetailTenyuryoku[], checkListDetailTenyuryoku[], checkD] => {
  const dispatch = useDispatch()
  const checkListDetail = useSelector((state) => state.TableTenyuryokuReducer.checkListDetail).filter((r) => r.isChecked)
  const checkListDetailAll = useSelector((state) => state.TableTenyuryokuReducer.checkListDetail)
  const allClick = useCallback(
    (shohinList: responseDetailTenyuryoku[], isChecked: boolean) => {
      return (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        e.preventDefault()
        dispatch(TableAction.allCheckDetail(isChecked, shohinList))
      }
    },
    [checkListDetail],
  )
  return [checkListDetail, checkListDetailAll, allClick]
}

/**
 * サマリボタン押下処理(CSV出力・承認・確認済・上長承認)
 * @param refetch
 * @returns
 */
export const useButtonsSummary = (refetch: voidF, tenyuryokuListCount: number, allCount: number): [boolean[], buttonQueryF, buttonQueryF, buttonQueryF, buttonQueryF] => {
  const dispatch = useDispatch()
  // ローディング
  const loading = useSelector((state) => state.PageReducer.loading)
  const SearchTenyuryoku = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)
  // const kakuninzumi = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininKakuninzumiCheckbox)
  const checkListTenyuryoku = useSelector((state) => state.TableTenyuryokuReducer.checkListTenyuryoku)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  const isHiddenPagination = useGetIsHiddenPagination()
  // 全件処理フラグ
  const isAllProcess = allCount > perPage && tenyuryokuListCount === checkListTenyuryoku.length && !isHiddenPagination

  // 販売条件サマリのチェックデータと検索項目からリクエストに必要な情報だけに絞り込む処理
  const getRequestSummary = (): requestSummaryTenyuryokuUpdate => {
    return {
      checkListSummary: checkListTenyuryoku,
      MAIL: MAIL,
      TANTO_CD: TANTO_CD,
      JOTAI: {
        mishonin: SearchTenyuryoku.mishoninCheckbox,
        jochoMishonin: SearchTenyuryoku.jochoMishoninCheckbox,
        shonin: SearchTenyuryoku.shoninCheckbox,
        jochoShonin: SearchTenyuryoku.jochoShoninCheckbox,
        hinin: SearchTenyuryoku.hininCheckbox,
        jochoHininMikakunin: SearchTenyuryoku.jochoHininMikakuninCheckbox,
        hininMikakunin: SearchTenyuryoku.hininMikakuninCheckbox,
        jochoHininKakuninzumi: SearchTenyuryoku.jochoHininKakuninzumiCheckbox,
        hininkakuninzumi: SearchTenyuryoku.hininKakuninzumiCheckbox,
      },
    }
  }
  // 販売条件サマリのチェックデータと検索項目からリクエストに必要な情報だけに絞り込む処理
  const getRequestSummaryCSV = (): requestSummaryTenyuryokuCSV => {
    return {
      userKind: userKind,
      isSummary: true,
      formatNo: fileCode.TENYURYOKU_CSV,
      JOTAI: {
        mishonin: SearchTenyuryoku.mishoninCheckbox,
        jochoMishonin: SearchTenyuryoku.jochoMishoninCheckbox,
        shonin: SearchTenyuryoku.shoninCheckbox,
        jochoShonin: SearchTenyuryoku.jochoShoninCheckbox,
        hinin: SearchTenyuryoku.hininCheckbox,
        jochoHininMikakunin: SearchTenyuryoku.jochoHininMikakuninCheckbox,
        hininMikakunin: SearchTenyuryoku.hininMikakuninCheckbox,
        jochoHininKakuninzumi: SearchTenyuryoku.jochoHininKakuninzumiCheckbox,
        hininkakuninzumi: SearchTenyuryoku.hininKakuninzumiCheckbox,
      },
      checkListSummary: checkListTenyuryoku,
    }
  }

  // 全選択時の承認、確認のリクエスト
  const getRequestTenyuryokuSummary = (): requestTenyuryokuSummary => getRequestTenyuryoku(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, SearchTenyuryoku)

  // 全選択時のCSV出力リクエスト
  const getRequestSummaryTenyuryokuAllCSV = (): requestSummaryTenyuryokuAllCSV => {
    return {
      userKind: userKind,
      isSummary: true,
      formatNo: fileCode.TENYURYOKU_CSV,
      requestTenyuryokuSummary: getRequestTenyuryoku(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, SearchTenyuryoku),
    }
  }

  // 全選択時のCSVカウントリクエスト
  const getRequestSummaryTenyuryokuAllCountCSV = (): requestSummaryTenyuryokuAllCountCSV => {
    return {
      requestTenyuryokuSummary: getRequestTenyuryoku(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, SearchTenyuryoku),
    }
  }

  // APIデータ取得後の反映処理
  const regist = (res: any) => {
    if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
    else {
      dispatch(TableAction.changeCheck(res.body))
      dispatch(PageAction.changeMessage(res.viewMsg, res.alert ? 'red' : 'blue'))
      // refetch後メッセージを削除されなくする
      dispatch(SearchAction.changeIsDeleteMessage(true))
      refetch()
    }
  }
  const changeErrMsg = () => dispatch(PageAction.changeErrorMessage()) //
  const loaded = () => dispatch(PageAction.changeLoading(false)) //

  // ボタン活性状況 //================================//
  const disabled1 = checkListTenyuryoku.length <= 0 // 1つ目のボタンの活性状況(CSV出力)
  let disabled2 = disabled1 // 2つ目のボタンの活性状況(承認)
  if (userKind === 0) {
    // ジャペルの場合(確認済)
    // disabled2 = kakuninzumi || !checkListTenyuryoku.some((r) => r.JOTAI_KBN_2 === 1)
    disabled2 = !checkListTenyuryoku.some((r) => r.JOTAI_KBN_2_1 === 1)
  }
  let disabled3 = !checkListTenyuryoku.some((r) => r.JOTAI_KBN_0 + r.JOTAI_KBN_1 > 0) // 3つ目のボタンの活性状況(上長承認)

  // CSVボタン押下処理 //================================//
  const clickCSV = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        // メッセージ消去
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          // CSV件数を取得
          let successGetCount = false
          let count = null
          await search<requestSummaryTenyuryokuAllCountCSV, any>(
            '/postTenyuryokuCSVCountAll',
            getRequestSummaryTenyuryokuAllCountCSV(),
            (res) => {
              if (!res.error) {
                successGetCount = true
                count = res.body
              }
            },
            changeErrMsg,
            () => {},
          )

          if (!successGetCount || !window.confirm(`${count}行あります。行数によっては時間がかかります。よろしいでしょうか？`)) {
            loaded()
            return
          }

          // CSV出力
          await search<requestSummaryTenyuryokuAllCSV, any>(
            '/postTenyuryokuCSVAll',
            getRequestSummaryTenyuryokuAllCSV(),
            (res) => {
              if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
              else {
                createCSV(userKind === 0 ? CSVHeaderTenyuryoku : CSVHeaderTenyuryokuSiire, res.body, res.fname)
                dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
              }
            },
            changeErrMsg,
            loaded,
          )
        } else {
          // 全件選択でない場合
          await search<requestSummaryTenyuryokuCSV, any>(
            '/postTenyuryokuCSV',
            getRequestSummaryCSV(),
            (res) => {
              if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
              else {
                createCSV(userKind === 0 ? CSVHeaderTenyuryoku : CSVHeaderTenyuryokuSiire, res.body, res.fname)
                dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
              }
            },
            changeErrMsg,
            loaded,
          )
        }
      }
    },
    [loading, userKind, SearchTenyuryoku, checkListTenyuryoku, isAllProcess],
  )
  // 承認ボタン押下処理 //================================//
  const clickShonin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          await search<requestTenyuryokuSummary, checkListSummaryTenyuryoku>('/postTenyuryokuShoninBulkAll', getRequestTenyuryokuSummary(), regist, changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<requestSummaryTenyuryokuUpdate, checkListSummaryTenyuryoku>('/postTenyuryokuShoninBulk', getRequestSummary(), regist, changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [loading, userKind, SearchTenyuryoku, checkListTenyuryoku, isAllProcess],
  )

  // 確認済ボタン押下処理 //================================//
  const clickKakunin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          await search<requestTenyuryokuSummary, checkListSummaryTenyuryoku>('/postTenyuryokuKakuninBulkAll', getRequestTenyuryokuSummary(), regist, changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<requestSummaryTenyuryokuUpdate, checkListSummaryTenyuryoku>('/postTenyuryokuKakuninBulk', getRequestSummary(), regist, changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [loading, userKind, SearchTenyuryoku, checkListTenyuryoku, isAllProcess],
  )
  // 上長承認ボタン押下処理 //================================//
  const clickJochoShonin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          await search<requestTenyuryokuSummary, checkListSummaryTenyuryoku>('/postTenyuryokuJochoShoninBulkAll', getRequestTenyuryokuSummary(), regist, changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<requestSummaryTenyuryokuUpdate, checkListSummaryTenyuryoku>('/postTenyuryokuJochoShoninBulk', getRequestSummary(), regist, changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [loading, userKind, SearchTenyuryoku, checkListTenyuryoku],
  )

  return [[disabled1, disabled2, disabled3], clickCSV, clickShonin, clickKakunin, clickJochoShonin]
}

/**
 * 明細ボタン押下処理(承認・否認・確認済・CSV出力・上長承認・戻る)
 * @returns
 */
export const useButtonsDetail = (history: any, detailListCount: number, allCount: number): [string, number, boolean[], clickFetchF, clickFetchF, clickFetchF, clickFetchF, clickFetchF, buttonQueryF, voidF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const clickNo = useSelector((state) => state.TableTenyuryokuReducer.clickNo)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  // const kakuninzumi = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininKakuninzumiCheckbox)
  const checkListDetail = useSelector((state) => state.TableTenyuryokuReducer.checkListDetail).filter((r) => r.isChecked)

  const DENPYO_NO = useSelector((state) => state.TableTenyuryokuReducer.clickNo)
  const searchTenyuryoku = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku)
  const TOKUI_CDS = useSelector((state) => state.TableTenyuryokuReducer.clickTokuiCds)
  const perPageDetail = useSelector((state) => state.TableReducer.perPageDetail)
  const isHiddenPagination = useGetIsHiddenPaginationDetail()
  // 全件処理フラグ
  const isAllProcess = allCount > perPageDetail && detailListCount === checkListDetail.length && !isHiddenPagination

  type shoninReq = {
    MAIL: string
    TANTO_CD: number
    DENPYO_NO: string
    checkListDetail: checkListDetailTenyuryoku[]
  }
  const request = {
    MAIL: MAIL,
    TANTO_CD: TANTO_CD,
    DENPYO_NO: clickNo,
    checkListDetail: checkListDetail,
  }
  const JOTAI: jotai = {
    mishonin: searchTenyuryoku.mishoninCheckbox,
    jochoMishonin: searchTenyuryoku.jochoMishoninCheckbox,
    shonin: searchTenyuryoku.shoninCheckbox,
    jochoShonin: searchTenyuryoku.jochoShoninCheckbox,
    hinin: searchTenyuryoku.hininCheckbox,
    jochoHininMikakunin: searchTenyuryoku.jochoHininMikakuninCheckbox,
    hininMikakunin: searchTenyuryoku.hininMikakuninCheckbox,
    jochoHininKakuninzumi: searchTenyuryoku.jochoHininKakuninzumiCheckbox,
    hininkakuninzumi: searchTenyuryoku.hininKakuninzumiCheckbox,
  }
  const requestTenyuryokuDetail: requestTenyuryokuDetail = getRequestTenyuryokuDetail(userKind, MAIL, TANTO_CD, DENPYO_NO, JOTAI, TOKUI_CDS, F_NUM, F_NUM, isHiddenPagination)

  const regist = (refetch: voidF) => {
    return (res: any) => {
      if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
      else {
        dispatch(TableAction.changeCheckDetail(res.body))
        dispatch(PageAction.changeMessage(res.viewMsg, res.alert ? 'red' : 'blue'))
        refetch()
      }
    }
  }
  const changeErrMsg = () => dispatch(PageAction.changeErrorMessage()) //
  const loaded = () => dispatch(PageAction.changeLoading(false)) //

  // ボタン活性状況 //================================//
  const disabled1 = checkListDetail.length <= 0 // 1つ目のボタンの活性状況(CSV出力)
  let disabled2 = disabled1 // 2つ目のボタンの活性状況(承認)
  if (userKind === 0) {
    // ジャペルの場合(確認済)
    // disabled2 = kakuninzumi || !checkListDetail.some((r) => r.JOTAI_KBN === 2)
    disabled2 = !checkListDetail.some((r: any) => r.JOTAI_KBN === 2 && r.KAKUNIN_KBN === 0)
  }
  const disabled3 = checkListDetail.length <= 0 // 3つ目のボタンの活性状況(否認)
  const disabled4 = !checkListDetail.some((r: any) => r.JOTAI_KBN === 0 || r.JOTAI_KBN === 1) // 4つ目のボタンの活性状況(上長承認)
  const disabled5 = !checkListDetail.some((r: any) => r.JOTAI_KBN === 0 || r.JOTAI_KBN === 1) // 5つ目のボタンの活性状況(上長否認)

  // 承認ボタン押下処理 //================================//
  const clickShonin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          await search<requestTenyuryokuDetail, checkListDetail>('/postTenyuryokuShohinDetailBulkAll', requestTenyuryokuDetail, regist(refetch), changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<shoninReq, checkListDetail>('/postTenyuryokuShohinDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading, isAllProcess, requestTenyuryokuDetail],
  )

  // 否認ボタン押下処理 //================================//
  const clickHinin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        await search<shoninReq, checkListDetail>('/postTenyuryokuHininDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading],
  )

  // 確認ボタン押下処理 //================================//
  const clickKakuninzumi = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          await search<requestTenyuryokuDetail, checkListDetail>('/postTenyuryokuKakuninDetailBulkAll', requestTenyuryokuDetail, regist(refetch), changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<shoninReq, checkListDetail>('/postTenyuryokuKakuninDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading, isAllProcess, requestTenyuryokuDetail],
  )

  // 上長承認ボタン押下処理 //================================//
  const clickJochoShonin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          await search<requestTenyuryokuDetail, checkListDetail>('/postTenyuryokuJochoShohinDetailBulkAll', requestTenyuryokuDetail, regist(refetch), changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<shoninReq, checkListDetail>('/postTenyuryokuJochoShohinDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading, isAllProcess, requestTenyuryokuDetail],
  )

  // 上長否認ボタン押下処理 //================================//
  const clickJochoHinin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        await search<shoninReq, checkListDetail>('/postTenyuryokuJochoHininDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading],
  )

  // CSVボタン押下処理 //================================//
  const clickCSV = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      if (isAllProcess) {
        // 全件選択の場合
        if (!window.confirm(`${allCount}行あります。行数によっては時間がかかります。よろしいでしょうか？`)) {
          loaded()
          return
        }

        await search<requestDetailTenyuryokuAllCSV, any>(
          '/postTenyuryokuCSVDetail',
          {
            userKind: userKind,
            isSummary: false,
            formatNo: fileCode.TENYURYOKU_CSV,
            requestTenyuryokuDetail: requestTenyuryokuDetail,
          },
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              createCSV(userKind === 0 ? CSVHeaderTenyuryoku : CSVHeaderTenyuryokuSiire, res.body, res.fname)
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
            }
          },
          changeErrMsg,
          loaded,
        )
      } else {
        // 全件選択でない場合
        await search<requestDetailTenyuryokuCSV, any>(
          '/postTenyuryokuCSV',
          {
            userKind: userKind,
            isSummary: false,
            formatNo: fileCode.TENYURYOKU_CSV,
            DENPYO_NO: clickNo,
            checkListDetail: checkListDetail,
          },
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              createCSV(userKind === 0 ? CSVHeaderTenyuryoku : CSVHeaderTenyuryokuSiire, res.body, res.fname)
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
            }
          },
          changeErrMsg,
          loaded,
        )
      }
    }
  }

  // 戻るボタン押下処理 //================================//
  const clickBack = useCallback(() => {
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      dispatch(PageAction.changeSideMenu())
      dispatch(SearchAction.closeDetail())
      dispatch(TableAction.format())
      history.push('/tenyuryoku')
    }
  }, [loading])

  return [clickNo, userKind, [disabled1, disabled2, disabled3, disabled4, disabled5], clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV, clickBack]
}

// 検索後フォーマット処理 //================================//
export const useSearchedFormatSummary = (): voidF => {
  const dispatch = useDispatch()
  const summaryFormat = () => {
    dispatch(TableAction.formatCheckSummary())
    // dispatch(PageAction.deleteMessage())
  }
  return summaryFormat
}

export const useSearchedFormatDetail = (): voidF => {
  const dispatch = useDispatch()
  const detailFormat = () => {
    dispatch(TableAction.formatCheckDetail())
    // dispatch(PageAction.deleteMessage())
  }
  return detailFormat
}

export const useSearchedFormatBoth = (): voidF => {
  const dispatch = useDispatch()
  const bothFormat = () => {
    dispatch(TableAction.formatCheckSummary())
    dispatch(TableAction.formatCheckDetail())
    // dispatch(PageAction.deleteMessage())
  }
  return bothFormat
}

// ページネーション非表示判定
export const useGetIsHiddenPagination = () => {
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const mishoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.mishoninCheckbox)
  const shoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.shoninCheckbox)
  const hininCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininCheckbox)
  const jochoShoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoShoninCheckbox)
  const jochoMishoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoMishoninCheckbox)
  const hininMikakuninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininMikakuninCheckbox)
  const jochoHininMikakuninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoHininMikakuninCheckbox)
  const hininKakuninzumiCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoHininKakuninzumiCheckbox)

  const initMishoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initMishoninCheckbox)
  const initShoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initShoninCheckbox)
  const initHininCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initHininCheckbox)
  const initJochoShoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initJochoShoninCheckbox)
  const initJochoMishoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initJochoMishoninCheckbox)
  const initHininMikakuninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initHininMikakuninCheckbox)
  const initJochoHininMikakuninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initJochoHininMikakuninCheckbox)
  const initHininkakuninzumiCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initHininkakuninzumiCheckbox)
  const initJochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initJochoHininKakuninzumiCheckbox)

  const getIsHiddenPagination = useCallback(() => {
    const isHiddenPagination =
      userKind === 0
        ? mishoninCheckbox === initMishoninCheckbox &&
          shoninCheckbox === initShoninCheckbox &&
          jochoShoninCheckbox === initJochoShoninCheckbox &&
          jochoMishoninCheckbox === initJochoMishoninCheckbox &&
          hininMikakuninCheckbox === initHininMikakuninCheckbox &&
          jochoHininMikakuninCheckbox === initJochoHininMikakuninCheckbox &&
          hininKakuninzumiCheckbox === initHininkakuninzumiCheckbox &&
          jochoHininKakuninzumiCheckbox === initJochoHininKakuninzumiCheckbox
        : mishoninCheckbox === initMishoninCheckbox && shoninCheckbox === initShoninCheckbox && hininCheckbox === initHininCheckbox
    return isHiddenPagination
  }, [mishoninCheckbox, shoninCheckbox, hininCheckbox, jochoShoninCheckbox, jochoMishoninCheckbox, hininMikakuninCheckbox, jochoHininMikakuninCheckbox, hininKakuninzumiCheckbox, jochoHininKakuninzumiCheckbox])
  return getIsHiddenPagination()
}

// 詳細画面ページネーション非表示判定
export const useGetIsHiddenPaginationDetail = () => {
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const mishoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.mishoninCheckbox)
  const shoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.shoninCheckbox)
  const hininCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininCheckbox)
  const jochoShoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoShoninCheckbox)
  const jochoMishoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoMishoninCheckbox)
  const hininMikakuninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininMikakuninCheckbox)
  const jochoHininMikakuninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoHininMikakuninCheckbox)
  const hininKakuninzumiCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoHininKakuninzumiCheckbox)

  const initMishoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initMishoninCheckbox)
  const initShoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initShoninCheckbox)
  const initHininCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initHininCheckbox)
  const initJochoShoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initJochoShoninCheckbox)
  const initJochoMishoninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initJochoMishoninCheckbox)
  const initHininMikakuninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initHininMikakuninCheckbox)
  const initJochoHininMikakuninCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initJochoHininMikakuninCheckbox)
  const initHininkakuninzumiCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initHininkakuninzumiCheckbox)
  const initJochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchTenyuryokuReducer.initJochoHininKakuninzumiCheckbox)

  const getIsHiddenPagination = useCallback(() => {
    const isHiddenPagination =
      userKind === 0
        ? mishoninCheckbox === initMishoninCheckbox &&
          shoninCheckbox === initShoninCheckbox &&
          jochoShoninCheckbox === initJochoShoninCheckbox &&
          jochoMishoninCheckbox === initJochoMishoninCheckbox &&
          hininMikakuninCheckbox === initHininMikakuninCheckbox &&
          jochoHininMikakuninCheckbox === initJochoHininMikakuninCheckbox &&
          hininKakuninzumiCheckbox === initHininkakuninzumiCheckbox &&
          jochoHininKakuninzumiCheckbox === initJochoHininKakuninzumiCheckbox
        : mishoninCheckbox === initMishoninCheckbox && shoninCheckbox === initShoninCheckbox && hininCheckbox === initHininCheckbox
    return isHiddenPagination
  }, [mishoninCheckbox, shoninCheckbox, hininCheckbox, jochoMishoninCheckbox, jochoMishoninCheckbox, hininMikakuninCheckbox, jochoHininMikakuninCheckbox, hininKakuninzumiCheckbox, jochoHininKakuninzumiCheckbox])
  return getIsHiddenPagination()
}
