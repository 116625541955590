/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import T_Main from 'components/4_Templates/T_Main'
import C1_HanJoken from 'components/5_Pages/Contents/C1_HanJoken'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import { menu } from 'types/ReferTypes'

const P_HanJoken = ({ sysName, menus }: { sysName: string; menus: menu[] }) => {
  const loginKbn = useSelector((state) => state.LoginReducer.loginKbn)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(PageAction.deleteMessage())
  }, [])

  if (loginKbn !== 2) {
    dispatch(LoginAction.format())
    history.push('/') // ログイン画面に遷移
    return <></>
  }

  return (
    <T_Main sysName={sysName} menus={menus} pageNo={0}>
      <C1_HanJoken />
    </T_Main>
  )
}

export default P_HanJoken
