/* eslint-disable react/jsx-pascal-case */
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import { useEffect } from 'react'
import T_Main from 'components/4_Templates/T_Main'
import C2_RitsuJoken from './Contents/C2_RitsuJoken'
import { menu } from 'types/ReferTypes'

const P_RitsuJoken = ({ sysName, menus }: { sysName: string; menus: menu[] }) => {
  const loginKbn = useSelector((state) => state.LoginReducer.loginKbn)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(PageAction.deleteMessage())
  }, [])

  if (loginKbn !== 2) {
    dispatch(LoginAction.format())
    history.push('/') // ログイン画面に遷移
    return <></>
  }

  return (
    <T_Main sysName={sysName} menus={menus} pageNo={2}>
      <C2_RitsuJoken />
    </T_Main>
  )
}

export default P_RitsuJoken
