import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as TableAction from 'actions/TableActions/TableJokenKuraAction'
import * as SearchAction from 'actions/SearchActions/SearchJokenKuraAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import { checkListDetailKura, checkListSummaryKura } from 'types/SearchTypes'
// 修正CSV出力対応 修正出力ボタン追加  20250221 原
// import { getRequestDetailCSV, getRequestJoken, getRequestJokenDetail, search } from 'utils/apiUtil'
import { getRequestDetailCSV, getRequestJoken, getRequestJokenDetail, search, getRequestDetailCSV_SYUSEI } from 'utils/apiUtil'
import { buttonQueryF, clickFetchF, voidF } from 'types/FunctionTypes'
import { fileCode } from 'assets/FileName'
import { createCSV } from 'utils/fileUtil'
// 修正CSV出力対応 修正出力ボタン追加  20250221 原
// import { CSVHeaderPreKuraJoken, CSVHeaderPreKuraJokenSiire, CSVHeaderPreKuraJokenSyusei } from 'assets/CSVHeaders'
// import { requestDetailAllCSV, requestDetailCSV, requestJokenDetail, requestJokenSummary, requestSummary, requestSummaryAllCSV, requestSummaryAllCountCSV, requestSummaryCSV } from 'types/RequestTypes'
import { CSVHeaderPreKuraJoken, CSVHeaderPreKuraJokenSiire, CSVHeaderPreKuraJokenSyusei } from 'assets/CSVHeaders'
import { requestDetailAllCSV, requestDetailCSV, requestJokenDetail, requestJokenSummary, requestSummary, requestSummaryAllCSV, requestSummaryAllCountCSV, requestSummaryCSV, requestSummaryCSV_SYUSEI, requestDetailCSV_SYUSEI } from 'types/RequestTypes'
import { responseDetailJokenKura, responseSummaryJokenKura } from 'types/ResponseTypes'
import { F_NUM, KURA_KBN } from 'assets/FormatNumbers'

// サマリチェックリスト //================================//
type check = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
export const useCheckListSummary = (jokenList: responseSummaryJokenKura[]): [checkListSummaryKura[], check] => {
  const dispatch = useDispatch()
  const checkListJoken = useSelector((state) => state.TableJokenKuraReducer.checkListJoken)
  const allClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => {
      e.preventDefault()
      dispatch(TableAction.allCheckJoken(isChecked, jokenList))
    },
    [jokenList],
  )
  return [checkListJoken, allClick]
}

// 明細チェックリスト //================================//
type checkD = (shohinList: responseDetailJokenKura[], isChecked: boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
export const useCheckListDetail = (): [checkListDetailKura[], checkListDetailKura[], checkD] => {
  const dispatch = useDispatch()
  const checkListDetail = useSelector((state) => state.TableJokenKuraReducer.checkListDetail).filter((r) => r.isChecked)
  const checkListDetailAll = useSelector((state) => state.TableJokenKuraReducer.checkListDetail)
  const allClick = useCallback(
    (shohinList: responseDetailJokenKura[], isChecked: boolean) => {
      return (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        e.preventDefault()
        dispatch(TableAction.allCheckShohin(isChecked, shohinList))
      }
    },
    [checkListDetail],
  )
  return [checkListDetail, checkListDetailAll, allClick]
}

/**
 * サマリボタン押下処理(CSV出力・承認・確認済・上長承認)
 * @param refetch
 * @param jokenListCount
 * @param allCount
 * @returns
 */
// 修正CSV出力対応 修正出力ボタン追加  20250221 原
// export const useButtonsSummary = (refetch: voidF, jokenListCount: number, allCount: number): [boolean[], buttonQueryF, buttonQueryF, buttonQueryF, buttonQueryF] => {
export const useButtonsSummary = (refetch: voidF, jokenListCount: number, allCount: number): [boolean[], buttonQueryF, buttonQueryF, buttonQueryF, buttonQueryF, buttonQueryF] => {
  const dispatch = useDispatch()
  // ローディング
  const loading = useSelector((state) => state.PageReducer.loading)
  const searchJoken = useSelector((state) => state.SearchJokenKuraReducer.searchJoken)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  // const kakuninzumi = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.hininKakuninzumiCheckbox)
  const checkListJoken = useSelector((state) => state.TableJokenKuraReducer.checkListJoken)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  const isHiddenPagination = useGetIsHiddenPagination()

  // 全件処理フラグ
  const isAllProcess = allCount > perPage && jokenListCount === checkListJoken.length && !isHiddenPagination

  // 販売条件サマリのチェックデータと検索項目からリクエストに必要な情報だけに絞り込む処理
  const getRequestSummary = (): requestSummary => {
    return {
      checkListSummary: checkListJoken,
      MAIL: MAIL,
      TANTO_CD: TANTO_CD,
      INP_KBN: KURA_KBN,
      JOTAI: {
        mishonin: searchJoken.mishoninCheckbox,
        jochoMishonin: searchJoken.jochoMishoninCheckbox,
        shonin: searchJoken.shoninCheckbox,
        jochoShonin: searchJoken.jochoShoninCheckbox,
        hinin: searchJoken.hininCheckbox,
        jochoHininMikakunin: searchJoken.jochoHininMikakuninCheckbox,
        hininMikakunin: searchJoken.hininMikakuninCheckbox,
        jochoHininKakuninzumi: searchJoken.jochoHininKakuninzumiCheckbox,
        hininkakuninzumi: searchJoken.hininKakuninzumiCheckbox,
      },
    }
  }
  // 販売条件サマリのチェックデータと検索項目からリクエストに必要な情報だけに絞り込む処理
  const getRequestSummaryCSV = (): requestSummaryCSV => {
    return {
      MAIL: MAIL,
      isSummary: true,
      formatNo: fileCode.KURADASHI_CSV,
      INP_KBN: KURA_KBN,
      checkListSummary: checkListJoken,
      JOTAI: {
        mishonin: searchJoken.mishoninCheckbox,
        jochoMishonin: searchJoken.jochoMishoninCheckbox,
        shonin: searchJoken.shoninCheckbox,
        jochoShonin: searchJoken.jochoShoninCheckbox,
        hinin: searchJoken.hininCheckbox,
        jochoHininMikakunin: searchJoken.jochoHininMikakuninCheckbox,
        hininMikakunin: searchJoken.hininMikakuninCheckbox,
        jochoHininKakuninzumi: searchJoken.jochoHininKakuninzumiCheckbox,
        hininkakuninzumi: searchJoken.hininKakuninzumiCheckbox,
      },
    }
  }
  // 修正CSV出力対応 修正出力ボタン追加  20250221 原
  // 販売条件サマリのチェックデータと検索項目からリクエストに必要な情報だけに絞り込む処理（修正用）
  const getRequestSummaryCSV_SYUSEI = (): requestSummaryCSV_SYUSEI => {
    return {
      MAIL: MAIL,
      isSummary: true,
      formatNo: fileCode.KURADASHI_SYUSEI_CSV,
      INP_KBN: KURA_KBN,
      checkListSummary: checkListJoken,
      JOTAI: {
        mishonin: searchJoken.mishoninCheckbox,
        jochoMishonin: searchJoken.jochoMishoninCheckbox,
        shonin: searchJoken.shoninCheckbox,
        jochoShonin: searchJoken.jochoShoninCheckbox,
        hinin: searchJoken.hininCheckbox,
        jochoHininMikakunin: searchJoken.jochoHininMikakuninCheckbox,
        hininMikakunin: searchJoken.hininMikakuninCheckbox,
        jochoHininKakuninzumi: searchJoken.jochoHininKakuninzumiCheckbox,
        hininkakuninzumi: searchJoken.hininKakuninzumiCheckbox,
      },
    }
  }
  // APIデータ取得後の反映処理
  const regist = (res: any) => {
    if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
    else {
      dispatch(TableAction.changeCheckJoken(res.body))
      dispatch(PageAction.changeMessage(res.viewMsg, res.alert ? 'red' : 'blue'))
      // refetch後メッセージを削除されなくする
      dispatch(SearchAction.changeIsDeleteMessage(true))
      refetch()
    }
  }
  const changeErrMsg = () => dispatch(PageAction.changeErrorMessage()) //
  const loaded = () => dispatch(PageAction.changeLoading(false)) //

  // ボタン活性状況 //================================//
  const disabled1 = checkListJoken.length <= 0 // 1つ目のボタンの活性状況(CSV出力)
  let disabled2 = disabled1 // 2つ目のボタンの活性状況(承認)
  if (userKind === 0) {
    // ジャペルの場合(確認済)
    // disabled2 = kakuninzumi || !checkListJoken.some((r) => r.JOTAI_KBN_2 === 1)
    disabled2 = !checkListJoken.some((r) => r.JOTAI_KBN_2_1 === 1)
  }
  let disabled3 = !checkListJoken.some((r) => r.JOTAI_KBN_0 + r.JOTAI_KBN_1 > 0) // 3つ目のボタンの活性状況(上長承認)

  // CSVボタン押下処理 //================================//
  const clickCSV = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        // メッセージ消去
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          // CSV件数を取得
          const requestJokenSummary: requestJokenSummary = getRequestJoken(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, KURA_KBN, searchJoken)
          let successGetCount = false
          let count = null
          await search<requestSummaryAllCountCSV, any>(
            '/postJokenCSVCountAll',
            {
              requestJokenSummary: requestJokenSummary,
            },
            (res) => {
              if (!res.error) {
                successGetCount = true
                count = res.body
              }
            },
            changeErrMsg,
            () => {},
          )

          if (!successGetCount || !window.confirm(`${count}行あります。行数によっては時間がかかります。よろしいでしょうか？`)) {
            loaded()
            return
          }

          // CSV出力
          await search<requestSummaryAllCSV, any>(
            '/postJokenCSVAll',
            {
              isSummary: true,
              formatNo: fileCode.KURADASHI_CSV,
              requestJokenSummary: requestJokenSummary,
            },
            (res) => {
              if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
              else {
                createCSV(userKind === 0 ? CSVHeaderPreKuraJoken : CSVHeaderPreKuraJokenSiire, res.body, res.fname)
                dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
              }
            },
            changeErrMsg,
            loaded,
          )
        } else {
          // 全件選択でない場合
          await search<requestSummaryCSV, any>(
            '/postJokenCSV',
            getRequestSummaryCSV(),
            (res) => {
              if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
              else {
                createCSV(userKind === 0 ? CSVHeaderPreKuraJoken : CSVHeaderPreKuraJokenSiire, res.body, res.fname)
                dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
              }
            },
            changeErrMsg,
            loaded,
          )
        }
      }
    },
    [loading, userKind, searchJoken, checkListJoken, MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, isHiddenPagination],
  )
  // 承認ボタン押下処理 //================================//
  const clickShonin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          const request: requestJokenSummary = getRequestJoken(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, KURA_KBN, searchJoken)
          await search<requestJokenSummary, checkListSummaryKura>('/postJokenShoninBulkAll', request, regist, changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<requestSummary, checkListSummaryKura>('/postJokenShoninBulk', getRequestSummary(), regist, changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [loading, userKind, searchJoken, checkListJoken, isAllProcess, MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, isHiddenPagination],
  )

  // 確認済ボタン押下処理 //================================//
  const clickKakunin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          const request: requestJokenSummary = getRequestJoken(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, KURA_KBN, searchJoken)
          await search<requestJokenSummary, checkListSummaryKura>('/postJokenKakuninBulkAll', request, regist, changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<requestSummary, checkListSummaryKura>('/postJokenKakuninBulk', getRequestSummary(), regist, changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [loading, userKind, searchJoken, checkListJoken, isAllProcess, MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, isHiddenPagination],
  )
  // 上長承認ボタン押下処理 //================================//
  const clickJochoShonin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          const request: requestJokenSummary = getRequestJoken(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, KURA_KBN, searchJoken)
          await search<requestJokenSummary, checkListSummaryKura>('/postJokenJochoShoninBulkAll', request, regist, changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<requestSummary, checkListSummaryKura>('/postJokenJochoShoninBulk', getRequestSummary(), regist, changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [loading, userKind, searchJoken, checkListJoken, isAllProcess, MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, isHiddenPagination],
  )
  // 修正CSV出力対応 修正出力ボタン追加  20250221 原
  // CSVボタン押下処理 //================================//
  const clickCSVSyusei = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        // メッセージ消去
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          // CSV件数を取得
          const requestJokenSummary: requestJokenSummary = getRequestJoken(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, F_NUM, F_NUM, isHiddenPagination, KURA_KBN, searchJoken)
          let successGetCount = false
          let count = null
          await search<requestSummaryAllCountCSV, any>(
            '/postJokenCSVCountAll',
            {
              requestJokenSummary: requestJokenSummary,
            },
            (res) => {
              if (!res.error) {
                successGetCount = true
                count = res.body
              }
            },
            changeErrMsg,
            () => {},
          )

          if (!successGetCount || !window.confirm(`${count}行あります。行数によっては時間がかかります。よろしいでしょうか？`)) {
            loaded()
            return
          }

          // CSV出力
          await search<requestSummaryAllCSV, any>(
            '/postJokenCSVAll',
            {
              isSummary: true,
              formatNo: fileCode.KURADASHI_SYUSEI_CSV,
              requestJokenSummary: requestJokenSummary,
            },
            (res) => {
              if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
              else {
                createCSV(userKind === 0 ? CSVHeaderPreKuraJokenSyusei : CSVHeaderPreKuraJokenSiire, res.body, res.fname)
                dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
              }
            },
            changeErrMsg,
            loaded,
          )
        } else {
          // 全件選択でない場合
          await search<requestSummaryCSV_SYUSEI, any>(
            '/postJokenCSV',
            getRequestSummaryCSV_SYUSEI(),
            (res) => {
              if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
              else {
                createCSV(userKind === 0 ? CSVHeaderPreKuraJokenSyusei : CSVHeaderPreKuraJokenSiire, res.body, res.fname)
                dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
              }
            },
            changeErrMsg,
            loaded,
          )
        }
      }
    },
    [loading, userKind, searchJoken, checkListJoken, MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, isHiddenPagination],
  )
  // 修正CSV出力対応 修正出力ボタン追加  20250221 原
  // return [[disabled1, disabled2, disabled3], clickCSV, clickShonin, clickKakunin, clickJochoShonin]
  return [[disabled1, disabled2, disabled3], clickCSV, clickShonin, clickKakunin, clickJochoShonin, clickCSVSyusei]
}

/**
 * 明細ボタン押下処理(承認・否認・確認済・CSV出力・上長承認・戻る)
 * @returns
 */
// 修正CSV出力対応 修正出力ボタン追加  20250221 原
// export const useButtonsDetail = (history: any, detailListCount: number, allCount: number): [string, number, boolean[], clickFetchF, clickFetchF, clickFetchF, clickFetchF, clickFetchF, buttonQueryF, voidF] => {
export const useButtonsDetail = (history: any, detailListCount: number, allCount: number): [string, number, boolean[], clickFetchF, clickFetchF, clickFetchF, clickFetchF, clickFetchF, buttonQueryF, voidF, buttonQueryF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const clickNo = useSelector((state) => state.TableJokenKuraReducer.clickNo)
  const TOKUI_CD = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.TOKUI_CD)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  // const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  // const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  // const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  // const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)
  // const searchJoken = useSelector((state) => state.SearchJokenKuraReducer.searchJoken)
  // const detailView = useSelector((state) => state.SearchJokenKuraReducer.detailView)
  // const kakuninzumi = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.hininKakuninzumiCheckbox)
  const checkListDetail = useSelector((state) => state.TableJokenKuraReducer.checkListDetail).filter((r) => r.isChecked)
  const searchJoken = useSelector((state) => state.SearchJokenKuraReducer.searchJoken)

  const isHiddenPagination = useGetIsHiddenPaginationDetail()

  const perPageDetail = useSelector((state) => state.TableReducer.perPageDetail)

  // 全件処理フラグ
  const isAllProcess = allCount > perPageDetail && detailListCount === checkListDetail.length && !isHiddenPagination

  type shoninReq = {
    MAIL: string
    TANTO_CD: number
    JOKEN_KANRI_NO: string
    TOKUI_CD: number
    INP_KBN: number
    checkListDetail: checkListDetailKura[]
  }
  const request = {
    MAIL: MAIL,
    TANTO_CD: TANTO_CD,
    JOKEN_KANRI_NO: clickNo,
    TOKUI_CD: TOKUI_CD,
    INP_KBN: KURA_KBN,
    checkListDetail: checkListDetail,
  }
  const regist = (refetch: voidF) => {
    return (res: any) => {
      if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
      else {
        dispatch(TableAction.changeCheckJokenDetail(res.body))
        dispatch(PageAction.changeMessage(res.viewMsg, res.alert ? 'red' : 'blue'))
        refetch()
      }
    }
  }
  const changeErrMsg = () => dispatch(PageAction.changeErrorMessage()) //
  const loaded = () => dispatch(PageAction.changeLoading(false)) //

  // ボタン活性状況 //================================//
  const disabled1 = checkListDetail.length <= 0 // 1つ目のボタンの活性状況(CSV出力)
  let disabled2 = disabled1 // 2つ目のボタンの活性状況(承認)
  if (userKind === 0) {
    // ジャペルの場合(確認済)
    // disabled2 = kakuninzumi || !checkListDetail.some((r) => r.JOTAI_KBN === 2)
    disabled2 = !checkListDetail.some((r) => r.JOTAI_KBN === 2 && r.KAKUNIN_KBN === 0)
  }
  const disabled3 = checkListDetail.length <= 0 // 3つ目のボタンの活性状況(否認)
  const disabled4 = !checkListDetail.some((r) => r.JOTAI_KBN === 0 || r.JOTAI_KBN === 1) // 4つ目のボタンの活性状況(上長承認)
  const disabled5 = !checkListDetail.some((r) => r.JOTAI_KBN === 0 || r.JOTAI_KBN === 1) // 5つ目のボタンの活性状況(上長否認)

  // 承認ボタン押下処理 //================================//
  const clickShonin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          const requestJokenDetail: requestJokenDetail = getRequestJokenDetail(F_NUM, F_NUM, isHiddenPagination, KURA_KBN, MAIL, clickNo, searchJoken, TANTO_CD)
          await search<requestJokenDetail, checkListDetailKura>('/postJokenShohinDetailBulkAll', requestJokenDetail, regist(refetch), changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<shoninReq, checkListDetailKura>('/postJokenShohinDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading, checkListDetail, isAllProcess, isHiddenPagination, MAIL, clickNo, searchJoken, TANTO_CD],
  )

  // 否認ボタン押下処理 //================================//
  const clickHinin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        await search<shoninReq, checkListDetailKura>('/postJokenHininDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading],
  )

  // 確認ボタン押下処理 //================================//
  const clickKakuninzumi = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          const requestJokenDetail: requestJokenDetail = getRequestJokenDetail(F_NUM, F_NUM, isHiddenPagination, KURA_KBN, MAIL, clickNo, searchJoken, TANTO_CD)
          await search<requestJokenDetail, checkListDetailKura>('/postJokenKakuninDetailBulkAll', requestJokenDetail, regist(refetch), changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<shoninReq, checkListDetailKura>('/postJokenKakuninDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading, isAllProcess, isHiddenPagination, MAIL, clickNo, searchJoken, TANTO_CD],
  )

  // 上長承認ボタン押下処理 //================================//
  const clickJochoShonin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        if (isAllProcess) {
          // 全件選択の場合
          const requestJokenDetail: requestJokenDetail = getRequestJokenDetail(F_NUM, F_NUM, isHiddenPagination, KURA_KBN, MAIL, clickNo, searchJoken, TANTO_CD)
          await search<requestJokenDetail, checkListDetailKura>('/postJokenJochoShohinDetailBulkAll', requestJokenDetail, regist(refetch), changeErrMsg, loaded)
        } else {
          // 全件選択でない場合
          await search<shoninReq, checkListDetailKura>('/postJokenJochoShohinDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        }
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading, isAllProcess, isHiddenPagination, MAIL, clickNo, searchJoken, TANTO_CD],
  )

  // 上長否認ボタン押下処理 //================================//
  const clickJochoHinin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        await search<shoninReq, checkListDetailKura>('/postJokenJochoHininDetailBulk', request, regist(refetch), changeErrMsg, loaded)
        dispatch(PageAction.changeSideMenu())
      }
    },
    [request, loading],
  )

  // CSVボタン押下処理 //================================//
  const clickCSV = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      if (isAllProcess) {
        // 全件選択の場合
        const requestJokenDetail: requestJokenDetail = getRequestJokenDetail(F_NUM, F_NUM, isHiddenPagination, KURA_KBN, MAIL, clickNo, searchJoken, TANTO_CD)

        if (!window.confirm(`${allCount}行あります。行数によっては時間がかかります。よろしいでしょうか？`)) {
          loaded()
          return
        }

        await search<requestDetailAllCSV, any>(
          '/postJokenCSVDetail',
          {
            isSummary: false,
            formatNo: fileCode.KURADASHI_CSV,
            requestJokenDetail: requestJokenDetail,
          },
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              console.log(res.body)
              createCSV(userKind === 0 ? CSVHeaderPreKuraJoken : CSVHeaderPreKuraJokenSiire, res.body, res.fname)
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
            }
          },
          changeErrMsg,
          loaded,
        )
      } else {
        // 全件選択でない場合
        await search<requestDetailCSV, any>(
          '/postJokenCSV',
          getRequestDetailCSV(fileCode.KURADASHI_CSV, clickNo, TOKUI_CD, KURA_KBN, checkListDetail),
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              createCSV(userKind === 0 ? CSVHeaderPreKuraJoken : CSVHeaderPreKuraJokenSiire, res.body, res.fname)
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
            }
          },
          changeErrMsg,
          loaded,
        )
      }
    }
  }

  // 全出力CSVボタン押下処理 //================================//
  // const clickAllCSV = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   e.preventDefault()
  //   if (!loading) {
  //     dispatch(PageAction.deleteMessage())
  //     // ローディング開始
  //     dispatch(PageAction.changeLoading(true))
  //     await search<requestKuraAllCSV, any>(
  //       '/postJokenKuraAllCSV',
  //       getRequestKuraAllCSV(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, searchJoken),
  //       (res) => {
  //         if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
  //         else {
  //           createCSV(userKind === 0 ? CSVHeaderPreHanJoken : CSVHeaderPreHanJokenSiire, res.body, res.fname)
  //           dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
  //         }
  //       },
  //       changeErrMsg,
  //       loaded,
  //     )
  //   }
  // }

  // 戻るボタン押下処理 //================================//
  const clickBack = useCallback(() => {
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      dispatch(PageAction.changeSideMenu())
      dispatch(SearchAction.closeDetail())
      dispatch(TableAction.format())
      history.push('/kuraJoken')
    }
  }, [loading])

  // const isAll = checkListDetail.length === perPageDetail && !useGetIsHiddenPaginationDetail()

  // 修正CSV出力対応 修正出力ボタン追加  20250221 原
  // CSVボタン押下処理 //================================//
  const clickCSVSyusei = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      if (isAllProcess) {
        // 全件選択の場合
        const requestJokenDetail: requestJokenDetail = getRequestJokenDetail(F_NUM, F_NUM, isHiddenPagination, KURA_KBN, MAIL, clickNo, searchJoken, TANTO_CD)

        if (!window.confirm(`${allCount}行あります。行数によっては時間がかかります。よろしいでしょうか？`)) {
          loaded()
          return
        }

        await search<requestDetailAllCSV, any>(
          '/postJokenCSVDetail',
          {
            isSummary: false,
            formatNo: fileCode.KURADASHI_SYUSEI_CSV,
            requestJokenDetail: requestJokenDetail,
          },
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              console.log(res.body)
              createCSV(userKind === 0 ? CSVHeaderPreKuraJokenSyusei : CSVHeaderPreKuraJokenSiire, res.body, res.fname)
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
            }
          },
          changeErrMsg,
          loaded,
        )
      } else {
        // 全件選択でない場合
        await search<requestDetailCSV_SYUSEI, any>(
          '/postJokenCSV',
          getRequestDetailCSV_SYUSEI(fileCode.KURADASHI_SYUSEI_CSV, clickNo, TOKUI_CD, KURA_KBN, checkListDetail),
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              createCSV(userKind === 0 ? CSVHeaderPreKuraJokenSyusei : CSVHeaderPreKuraJokenSiire, res.body, res.fname)
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
            }
          },
          changeErrMsg,
          loaded,
        )
      }
    }
  }
  // 修正CSV出力対応 修正出力ボタン追加  20250221 原
  // return [clickNo, userKind, [disabled1, disabled2, disabled3, disabled4, disabled5], clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV, clickBack]
  return [clickNo, userKind, [disabled1, disabled2, disabled3, disabled4, disabled5], clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV, clickBack, clickCSVSyusei]
}

// 検索後フォーマット処理 //================================//
export const useSearchedFormatSummary = (): voidF => {
  const dispatch = useDispatch()
  const summaryFormat = () => {
    dispatch(TableAction.formatCheckSummary())
    // dispatch(PageAction.deleteMessage())
  }
  return summaryFormat
}

export const useSearchedFormatDetail = (): voidF => {
  const dispatch = useDispatch()
  const detailFormat = () => {
    dispatch(TableAction.formatCheckDetail())
    // dispatch(PageAction.deleteMessage())
  }
  return detailFormat
}

export const useSearchedFormatBoth = (): voidF => {
  const dispatch = useDispatch()
  const bothFormat = () => {
    dispatch(TableAction.formatCheckSummary())
    dispatch(TableAction.formatCheckDetail())
    // dispatch(PageAction.deleteMessage())
  }
  return bothFormat
}

// ページネーション非表示判定
export const useGetIsHiddenPagination = () => {
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const mishoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.mishoninCheckbox)
  const shoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.shoninCheckbox)
  const hininCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.hininCheckbox)
  const jochoShoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.jochoShoninCheckbox)
  const jochoMishoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.jochoMishoninCheckbox)
  const hininMikakuninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.hininMikakuninCheckbox)
  const jochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.jochoHininMikakuninCheckbox)
  const hininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchJoken.jochoHininKakuninzumiCheckbox)

  const initMishoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initMishoninCheckbox)
  const initShoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initShoninCheckbox)
  const initHininCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initHininCheckbox)
  const initJochoShoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initJochoShoninCheckbox)
  const initJochoMishoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initJochoMishoninCheckbox)
  const initHininMikakuninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initHininMikakuninCheckbox)
  const initJochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initJochoHininMikakuninCheckbox)
  const initHininkakuninzumiCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initHininkakuninzumiCheckbox)
  const initJochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initJochoHininKakuninzumiCheckbox)

  const getIsHiddenPagination = useCallback(() => {
    const isHiddenPagination =
      userKind === 0
        ? mishoninCheckbox === initMishoninCheckbox &&
          shoninCheckbox === initShoninCheckbox &&
          jochoShoninCheckbox === initJochoShoninCheckbox &&
          jochoMishoninCheckbox === initJochoMishoninCheckbox &&
          hininMikakuninCheckbox === initHininMikakuninCheckbox &&
          jochoHininMikakuninCheckbox === initJochoHininMikakuninCheckbox &&
          hininKakuninzumiCheckbox === initHininkakuninzumiCheckbox &&
          jochoHininKakuninzumiCheckbox === initJochoHininKakuninzumiCheckbox
        : mishoninCheckbox === initMishoninCheckbox && shoninCheckbox === initShoninCheckbox && hininCheckbox === initHininCheckbox
    return isHiddenPagination
  }, [mishoninCheckbox, shoninCheckbox, hininCheckbox, jochoShoninCheckbox, jochoMishoninCheckbox, hininMikakuninCheckbox, jochoHininMikakuninCheckbox, hininKakuninzumiCheckbox, jochoHininKakuninzumiCheckbox])
  return getIsHiddenPagination()
}

// 詳細画面ページネーション非表示判定
export const useGetIsHiddenPaginationDetail = () => {
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const mishoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.mishoninCheckbox)
  const shoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.shoninCheckbox)
  const hininCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.hininCheckbox)
  const jochoShoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.jochoShoninCheckbox)
  const jochoMishoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.jochoMishoninCheckbox)
  const hininMikakuninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.hininMikakuninCheckbox)
  const jochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.jochoHininMikakuninCheckbox)
  const hininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenKuraReducer.searchedJoken.jochoHininKakuninzumiCheckbox)

  const initMishoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initMishoninCheckbox)
  const initShoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initShoninCheckbox)
  const initHininCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initHininCheckbox)
  const initJochoShoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initJochoShoninCheckbox)
  const initJochoMishoninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initJochoMishoninCheckbox)
  const initHininMikakuninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initHininMikakuninCheckbox)
  const initJochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initJochoHininMikakuninCheckbox)
  const initHininkakuninzumiCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initHininkakuninzumiCheckbox)
  const initJochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenKuraReducer.initJochoHininKakuninzumiCheckbox)

  const getIsHiddenPagination = useCallback(() => {
    const isHiddenPagination =
      userKind === 0
        ? mishoninCheckbox === initMishoninCheckbox &&
          shoninCheckbox === initShoninCheckbox &&
          jochoShoninCheckbox === initJochoShoninCheckbox &&
          jochoMishoninCheckbox === initJochoMishoninCheckbox &&
          hininMikakuninCheckbox === initHininMikakuninCheckbox &&
          jochoHininMikakuninCheckbox === initJochoHininMikakuninCheckbox &&
          hininKakuninzumiCheckbox === initHininkakuninzumiCheckbox &&
          jochoHininKakuninzumiCheckbox === initJochoHininKakuninzumiCheckbox
        : mishoninCheckbox === initMishoninCheckbox && shoninCheckbox === initShoninCheckbox && hininCheckbox === initHininCheckbox
    return isHiddenPagination
  }, [mishoninCheckbox, shoninCheckbox, hininCheckbox, jochoMishoninCheckbox, jochoMishoninCheckbox, hininMikakuninCheckbox, jochoHininMikakuninCheckbox, hininKakuninzumiCheckbox, jochoHininKakuninzumiCheckbox])
  return getIsHiddenPagination()
}
