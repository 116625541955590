/**
 * JOM170から取得するファイル名一覧
 * ※コメントのファイル名は変わる可能性があります。
 */
export const fileCode = {
  HANBAI_CSV: 33, // 【JS3】販売条件CSV
  RITSU_CSV: 34, // 【JS3】率条件CSV
  RITSU_JOGAI_CSV: 20, // 【精算金】除外アイテムCSV
  TENYURYOKU_CSV: 38, // 【JS3】手入力分照会CSV
  KURADASHI_CSV: 42, // 【JS3】販売条件(事業所)CSV
  HANBAI_SYUSEI_CSV: 43, // 【販売精算金】販売条件(得意先)CSV   // 修正CSV出力対応 修正出力ボタン追加  20250219 原
  KURADASHI_SYUSEI_CSV: 44, // 【販売精算金】販売条件(事業所)CSV   // 修正CSV出力対応 修正出力ボタン追加  20250221 原
  USER_CSV: 45, // 【JS3】ユーザ一覧CSV   // ユーザ一覧CSV出力対応 CSV出力ボタン追加 20250303 原
}
