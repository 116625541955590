import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Badge from '@material-ui/core/Badge'
import { organismLog } from 'utils/logUtil'
import { useSetChoice } from 'hooks/useCommon'
import { menu } from 'types/ReferTypes'
import { useFetcherSideMenus } from 'hooks/useCommon'

const drawerWidth = 210

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#F3FFD8',
    },
    drawerContainer: {
      overflow: 'auto',
    },
  }),
)

type Props = {
  menus: menu[]
  openMenu: boolean
}

const O_SideMenu = ({ menus, openMenu }: Props) => {
  useEffect(() => organismLog('O_SideMenu'))
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [userKind, setChoice] = useSetChoice(history, location)

  // 未承認件数取得
  const [sideMenu, fetcher] = useFetcherSideMenus()
  const datas = useQuery(['getMishonin', sideMenu], fetcher)
  const mishoninList: number[] = datas.error || datas.isFetching || datas.isLoading ? [] : datas.data.body

  if (!openMenu) return <></>
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List dense>
          {menus.map((menu, index) => {
            const selected = menu.path === location.pathname
            if (userKind === 0 && menu.path === '/changePassword') {
              // ジャペルのときはパスワード変更をメニューに表示しない
              return null
            }
            if (userKind === 1 && menu.path === '/userToroku') {
              // 仕入先のときはユーザー登録をメニューに表示しない
              return null
            }
            if (userKind === 1 && menu.path === '/log') {
              // 仕入先のときはログ照会をメニューに表示しない
              return null
            }
            if (menu.path === '/manual') {
              // マニュアル表示は別タブへリンクさせる
              let path = ''
              if (userKind === 0) path = `https://${window.location.hostname}/manual/${process.env.REACT_APP_PDF_JAPELL}`
              else if (userKind === 1) path = `https://${window.location.hostname}/manual/${process.env.REACT_APP_PDF_SIIRE}`
              return (
                <ListItem key={index}>
                  <a href={path} target="_blank" rel={'noopener'}>
                    {menu.label}
                  </a>
                </ListItem>
              )
            }
            return (
              <ListItem key={index} button selected={selected} onClick={() => setChoice(selected, menu.path)}>
                <ListItemText primary={menu.label} />
                {mishoninList && mishoninList[index] > 0 ? <Badge badgeContent={`${mishoninList[index]}`} color="secondary" /> : ''}
              </ListItem>
            )
          })}
        </List>
      </div>
    </Drawer>
  )
}
export default React.memo(O_SideMenu)
