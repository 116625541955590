/* eslint-disable react/jsx-pascal-case */
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import CssBaseline from '@material-ui/core/CssBaseline'
import O_Header from 'components/3_Organisms/O_Header'
import O_SideMenu from 'components/3_Organisms/O_SideMenu'
import O_ContentHeader from 'components/3_Organisms/O_ContentHeader'
import { templateLog } from 'utils/logUtil'
import { getMenuLabel } from 'utils/stringUtil'
import { menu } from 'types/ReferTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      backgroundColor: '#F3FFD8',
    },
  }),
)

type Props = {
  sysName: string
  menus: menu[]
  pageNo: number
  expandMode?: boolean // 検索項目を拡縮できるか
  children: ReactNode
}

const T_Main = ({ sysName, menus, pageNo, expandMode = false, children }: Props): JSX.Element => {
  useEffect(() => templateLog('T_Main'))
  const classes = useStyles()
  // サイドメニュー開閉処理
  const [openMenu, changeOpenMenu] = useState(true)
  const handleOpenMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      changeOpenMenu(!openMenu)
    },
    [openMenu],
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <O_Header sysName={sysName} handleOpenMenu={handleOpenMenu} />
      <O_SideMenu menus={menus} openMenu={openMenu} />
      <main className={classes.content}>
        <Toolbar variant="dense" />
        <O_ContentHeader title={getMenuLabel(menus[pageNo])} expandMode={expandMode} />
        <Paper className={classes.paper}>{children}</Paper>
      </main>
    </div>
  )
}

export default React.memo(T_Main)
