/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import T_Main from 'components/4_Templates/T_Main'
import C3_UserToroku from './Contents/C4_UserToroku'
import { menu } from 'types/ReferTypes'

const P_UserToroku = ({ sysName, menus }: { sysName: string; menus: menu[] }) => {
  // ログインユーザーの種類(0: ジャペル, 1: 仕入先)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const loginKbn = useSelector((state) => state.LoginReducer.loginKbn)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    if (loginKbn !== 2 || userKind === 1) {
      dispatch(LoginAction.format())
      history.push('/') // ログイン画面に遷移
    } else {
      dispatch(PageAction.deleteMessage())
    }
  }, [])

  return (
    <T_Main sysName={sysName} menus={menus} pageNo={5}>
      <C3_UserToroku />
    </T_Main>
  )
}

export default P_UserToroku
