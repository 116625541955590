import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import M_ButtonNormal from 'components/1_Atoms/2_Molecules/M_ButtonNormal'
import O_TablePagination from './O_TablePagination'
import { organismLog } from 'utils/logUtil'
import M_ButtonAlert from 'components/1_Atoms/2_Molecules/M_ButtonAlert'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import { buttonQueryF, clickFetchF, voidF } from 'types/FunctionTypes'
import { useGetIsHiddenPaginationDetail } from 'hooks/useKuraJokenTable'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    hit: {
      marginRight: theme.spacing(1),
      // width: 250
    },
    buttons: {
      // flexGrow: 1,
      // width: 190
    },
    paddingRight0: {
      paddingRight: '0px',
    },
    pageNation: {
      flexGrow: 1,
      display: 'flex',
      // justifyContent: 'flex-end',
      // align: 'right'
    },
    backButton: {
      // flexGrow: 1,
      // display: 'flex',
      // justifyContent: 'flex-end',
    },
  }),
)
const margin = 0
const paddingX = 0.5
const paddingY = 0

type Props = {
  count?: number
  checkedList?: boolean[]
  userKind?: number
  clickShonin: clickFetchF
  clickHinin: clickFetchF
  clickKakuninzumi: clickFetchF
  clickJochoShonin: clickFetchF
  clickJochoHinin: clickFetchF
  clickCSV: buttonQueryF
  clickBack: voidF
  refetch: voidF
  clickCSVSyusei: buttonQueryF // 修正CSV出力対応 修正出力ボタン追加  20250221 原
}

{
  /* 修正CSV出力対応 修正出力ボタン追加  20250221 原 */
}
// const O_TableBarKuraDetail = ({ count = 0, checkedList = [false, false, false], userKind = 0, clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV, clickBack, refetch }: Props) => {
const O_TableBarKuraDetail = ({ count = 0, checkedList = [false, false, false], userKind = 0, clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV, clickBack, refetch, clickCSVSyusei }: Props) => {
  useEffect(() => organismLog('O_TableBarKuraDetail'))
  const classes = useStyles()
  const isHiddenPagination = useGetIsHiddenPaginationDetail()
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)

  return (
    <div className={classes.root}>
      <Toolbar variant="dense" className={classes.paddingRight0}>
        <Typography className={classes.hit} color="inherit" variant="subtitle1" component="div">
          {count}件
        </Typography>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonNormal label={'CSV出力'} event={clickCSV} disabled={checkedList[0]} />
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          {userKind === 0 ? <M_ButtonNormal label={'確認済'} event={(e) => clickKakuninzumi(e, refetch)} disabled={checkedList[1]} /> : <M_ButtonNormal label={'承認'} event={(e) => clickShonin(e, refetch)} disabled={checkedList[1]} />}
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          {userKind === 0 ? null : <M_ButtonAlert label={'否認'} event={(e) => clickHinin(e, refetch)} disabled={checkedList[2]} />}
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          {isJocho ? <M_ButtonNormal label={'上長承認'} event={(e) => clickJochoShonin(e, refetch)} disabled={checkedList[3]} /> : null}
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          {isJocho ? <M_ButtonAlert label={'上長否認'} event={(e) => clickJochoHinin(e, refetch)} disabled={checkedList[4]} /> : null}
        </Box>
        {/* 修正CSV出力対応 修正出力ボタン追加  20250221 原 */}
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          {userKind === 0 ? <M_ButtonNormal label={'販売精算金CSV出力'} event={clickCSVSyusei} disabled={checkedList[0]} /> : null}
        </Box>
        <Box className={classes.root}></Box>
        <Box className={classes.pageNation}>{isHiddenPagination ? null : <O_TablePagination count={count} isDetail={true} />}</Box>
        <Box className={classes.backButton} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonSubmit label={'戻る'} event={clickBack} />
        </Box>
      </Toolbar>
    </div>
  )
}

export default React.memo(O_TableBarKuraDetail)
